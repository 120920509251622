const getArrayObjConcat = (arrayA, arrayB) => {
  if (arrayA.length !== arrayB.length) return null
  let concatArray = []
  for (let i in arrayA) {
    concatArray[i] = { ...arrayA[i], ...arrayB[i] }
  }
  return concatArray
}

export default getArrayObjConcat
