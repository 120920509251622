import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"


//Components
import PictureGrid from "../../utils/get-pictureGrid"
import Tab from "../../components/history/tabs"
import Table from "../../components/table"

//Hooks
import useExhibitionList from "../../hooks/use-exhibition-list"
import useExhibitionPhotos from "../../hooks/use-exhibition-photos"
import useExhibitionPhotoList from "../../hooks/use-exhibition-photos-list"
import getArrayObjConcat from "../../utils/get-arrayObject-concat"

//CSS
import "./css/exhibition.styl"

const Exhibtion = ({ location }) => {


  const intl = useIntl()
  const language = intl.locale
  const exhibitionList = useExhibitionList()
  const exhibitionPhotos = useExhibitionPhotos()
  const exhibitionPhotosList = useExhibitionPhotoList()
  const exhibitionPhotoItems = getArrayObjConcat(
    exhibitionPhotos,
    exhibitionPhotosList
  )

  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Exhibtion" : "展示歴"}
        keywords={[`exhibtion`, `展示歴`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Exhibition history from international photo festivals to local events` : `国際写真祭からイベント展示まで国内外におけるこれまでの展示歴です。`}
      />
      <Tab location={location}>
        <section className="history exhibition component">
          <Table items={exhibitionList} />
          <PictureGrid items={exhibitionPhotoItems} />
        </section>
      </Tab>
    </Layout>
  )
}

export default Exhibtion
