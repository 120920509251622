import { useStaticQuery, graphql } from "gatsby"

const useExhibitionList = () => {
  const { allExhibitionJson } = useStaticQuery(
    graphql`
      query {
        allExhibitionJson {
          nodes {
            _id
            field {
              en
              ja
            }
            content {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allExhibitionJson.nodes
}

export default useExhibitionList
