import React from "react"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import "./css/pictureGrid.styl"

const PictureGrid = ({ items }) => {
  const intl = useIntl()
  const language = intl.locale

  return (
    <ul className="pictureGrid">
      {items.map(item => (
        <li key={item._id}>
          <Img fluid={item.childImageSharp.fluid} />
          <div>
            <span className={"caption"}>
              {item.title[language]}{" "}
              <span className="date">( {item.date} )</span>
            </span>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default PictureGrid
