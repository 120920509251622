import React from "react"
import { useIntl } from "gatsby-plugin-intl"

const Table = ({ items }) => {
  const intl = useIntl()
  const language = intl.locale

  return (
    <ul className="table">
      {items.map(item => (
        <React.Fragment key={"field_" + item._id}>
          <li className="field">{item.field[language]}</li>
          <li className="content">{item.content[language]}</li>
        </React.Fragment>
      ))}
    </ul>
  )
}

export default Table
