import React, { useState, useEffect } from "react"
import "./css/tabs.styl"
import { Link, useIntl } from "gatsby-plugin-intl"

function Tab({ children, location: { pathname } }) {
  const [tab, setTab] = useState(pathname.split("/history/")[1])
  const intl = useIntl()
  const language = intl.locale

  return (
    <>
      <ul className="historyTabs">
        <li>
          <Link
            to="/history/press"
            className={tab.indexOf("press") >= 0 ? "active" : ""}
          >
            {language === "en" ? "Press" : "掲載歴"}
          </Link>
        </li>
        <li>
          <Link
            to="/history/exhibition"
            className={tab.indexOf("exhibition") >= 0 ? "active" : ""}
          >
            {language === "en" ? "Exhibition" : "展示歴"}
          </Link>
        </li>
        <li>
          <Link
            to="/history/award"
            className={tab.indexOf("award") >= 0 ? "active" : ""}
          >
            {language === "en" ? "Award" : "受賞歴"}
          </Link>
        </li>
      </ul>
      {children}
    </>
  )
}

export default Tab
