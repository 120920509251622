import { useStaticQuery, graphql } from "gatsby"

const useExhibitionPhotoList = () => {
  const { allExhibitionPhotosJson } = useStaticQuery(
    graphql`
      query {
        allExhibitionPhotosJson(sort: { fields: _id, order: DESC }) {
          nodes {
            _id
            title {
              en
              ja
            }
            date
          }
        }
      }
    `
  )

  return allExhibitionPhotosJson.nodes
}

export default useExhibitionPhotoList
